export const environment = {
  production: true,
  APP_BACKEND_URL: 'https://api.digital-punchlist.swf.si.siemens.cloud',
  COGNITO_DOMAIN_URL: 'https://d2go-mdp-si-swf-digital-punchlist.auth.eu-west-1.amazoncognito.com',
  AUTHORIZE_URL: '/oauth2/authorize',
  AUTHORIZE_RESPONSE_TYPE: 'code',
  AUTHORIZE_SCOPE: 'email openid phone profile',
  IDENTITY_PROVIDER: 'AzureAD',
  COGNITO_CLIENT_ID: '7udvib478rhkl7fffjfuub6lsu',
  COGNITO_REDIRECT_URI: 'https://digital-punchlist.swf.si.siemens.cloud/oauth2/idpresponse'
};
